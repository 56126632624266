










































































































































import { debounceProcess } from "@/helpers/debounce";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { Messages } from "@/models/enums/messages.enum";
import { ContactData } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { contactServices } from "@/services/contact.service";
import { logisticServices } from "@/services/logistic.service";
import moment from "moment";
import Vue from "vue";
import { salesOrderServices } from "@/services/salesorder.service";
import {
  DataResponseBastHasBeenCreated,
  DataResponseBastState,
} from "@/models/interface/salesOrder.interface";

interface LoadingComponent {
  loadingFind: boolean;
  loadingBastNumber: boolean;
  download: boolean;
  print: boolean;
  loadingCustomerName: boolean;
  loadingStatus: boolean;
}

export default Vue.extend({
  name: "List Posting Serah Terima",
  data() {
    this.getBastNumber = debounceProcess(this.getBastNumber, 200);
    this.getCustomerName = debounceProcess(this.getCustomerName, 200);
    this.getStatus = debounceProcess(this.getStatus, 200);
    return {
      form: this.$form.createForm(this, { name: "bastSerahTerimaPosting" }),
      page: this.page || (1 as number),
      limit: this.limit || (10 as number),
      params: {} as RequestQueryParamsModel,
      totalData: 0 as number,
      loading: {
        loadingFind: false,
        loadingBastNumber: false,
        download: false,
        print: false,
        loadingCustomerName: false,
        loadingStatus: false,
      } as LoadingComponent,
      columnsTable: [
        {
          title: "No",
          dataIndex: "no",
          key: "no",
          width: 110,
          scopedSlots: { customRender: "no" },
        },
        {
          title: "Bast Number",
          dataIndex: "bastNumber",
          key: "bastNumber",
          width: 200,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Bast Date",
          dataIndex: "bastDate",
          key: "bastDate",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "SO Number",
          dataIndex: "soNumber",
          key: "soNumber",
          width: 170,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_customer_name"),
          dataIndex: "customerName",
          key: "customerName",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Status",
          dataIndex: "state",
          key: "state",
          width: 150,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$root.$t("lbl_action").toString(),
          dataIndex: "operation",
          key: "operation",
          scopedSlots: { customRender: "operation" },
          button: ["view"],
          width: 120,
          align: "center",
        },
      ],
      formRules: {
        bastNumber: {
          label: "Bast Number",
          name: "bastNumber",
          placeholder: "Select Bast Number",
          decorator: [
            "bastNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        customerName: {
          label: "lbl_customer_name",
          name: "customerName",
          placeholder: "lbl_customer_name_placeholder",
          decorator: [
            "customerName",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        status: {
          label: "Status",
          name: "status",
          placeholder: "Select Status",
          decorator: [
            "status",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
      dataSource: [] as DataResponseBastHasBeenCreated[],
      dataBastNumber: [] as DataResponseBastHasBeenCreated[],
      dataCustomerName: [] as ContactData[],
      dataStatus: [] as DataResponseBastState[],
      woNumber: "" as string,
    };
  },
  methods: {
    handleDownload() {
      this.form.validateFields((err, res) => {
        if (err) return;
        this.loading.download = true;
        this.params.params = this.checkParams(res);
        logisticServices
          .getDownloadResultdWorkOrder(this.params)
          .then(response => {
            if (response) {
              const url = window.URL.createObjectURL(new Blob([response]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "report_customer_maintenance.xlsx"); //or any other extension
              document.body.appendChild(link);
              link.click();
            }
          })
          .finally(() => (this.loading.download = false));
      });
    },
    responseViewTable(response) {
      this.$router.push(
        `/sales/serah-terima/balikan/detail/${response.data.id}`
      );
    },
    handleWorkOrderNumber(value) {
      this.woNumber = value;
      this.getBastNumber("");
      this.form.resetFields(["csfNumber"]);
    },
    getBastNumber(valueSearch) {
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      if (valueSearch) params.search = `documentNo~*${valueSearch}*`;
      this.loading.loadingBastNumber = true;
      salesOrderServices.getListBastHasBeenCreated(params).then(response => {
        this.dataBastNumber = response.data;
      });
      this.loading.loadingBastNumber = false;
    },
    getCustomerName(valueSearch) {
      const params: RequestQueryParamsModel = {
        limit: 10,
        page: 0,
        search: "customer~true_AND_active~true",
      };
      if (valueSearch)
        params.search += `_AND_firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*`;
      this.loading.loadingCustomerName = true;
      contactServices
        .listContactData(params)
        .then(response => (this.dataCustomerName = response.data))
        .finally(() => (this.loading.loadingCustomerName = false));
    },
    getStatus(valueSearch) {
      const params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
      };
      if (valueSearch) params.search = `key~${valueSearch}`;
      this.loading.loadingStatus = true;
      salesOrderServices.getListBastState(params).then(response => {
        this.dataStatus = response;
      });
      this.loading.loadingStatus = false;
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.onSubmit();
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.onSubmit();
    },
    checkValue(value): string {
      if (value && !value.includes("undefined")) {
        return value;
      } else {
        return ",ALL";
      }
    },
    checkParams(res): string {
      let params = "";
      // company is mandatory
      params += this.$store.state.authStore.authData.companyName;
      params += this.checkValue(`,${res["customerMaintenanceNumber"]}`);
      params += this.checkValue(`,${res["workOrderNumber"]}`);
      params += this.checkValue(`,${res["csfNumber"]}`);
      params += this.checkValue(`,${res["customerName"]}`);
      params += this.checkValue(`,${res["unitCode"]}`);
      params += this.checkValue(
        res["dateFrom"]
          ? `,${moment(res["dateFrom"]).format("DD-MMM-yyyy")}`
          : null
      );
      params += this.checkValue(
        res["dateTo"] ? `,${moment(res["dateTo"]).format("DD-MMM-yyyy")}` : null
      );

      return params;
    },
    assignSearch(key, value, and): string {
      if (key === "bastNumber" && value) return and + `documentNo~*${value}*`;
      else if (key === "customerName" && value)
        return and + `deliveryOrder.customer.secureId~${value}`;
      else if (key === "status" && value) return and + `state~${value}`;
      else return "";
    },
    dynamicSearch(res): string {
      let search = "";
      Object.keys(res).forEach(key => {
        if (!search) {
          search = this.assignSearch(key, res[key], "");
        } else {
          search += this.assignSearch(key, res[key], "_AND_");
        }
      });
      return search;
    },
    onSubmit(): void {
      this.form.validateFields((err, values) => {
        if (err) return;
        const params: RequestQueryParamsModel = {
          page: this.page - 1,
          limit: this.limit,
        };
        params.search = this.dynamicSearch(values);
        this.loading.loadingFind = true;
        salesOrderServices
          .getListBastHasBeenCreated(params)
          .then(response => {
            this.totalData = response.totalElements;
            this.params.params = this.checkParams(values);
            this.dataSource = response.data.map((dataMap, key) => {
              return { ...dataMap, key, no: key + 1 };
            });
          })
          .finally(() => (this.loading.loadingFind = false));
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  created() {
    this.getCustomerName("");
    this.getStatus("");
    this.getBastNumber("");
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
  },
});
